import { useState, useEffect, Key, useRef } from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import { useQuery } from '@apollo/client';
import dynamic from 'next/dynamic';
import Button from '../components/CustomButton';
import styles from '../styles/Home.module.css';
import ProductCard from '../components/ProductCard';
import Testimonial from '../components/Testimonials/Testimonial';
import { GET_HOMEPAGE } from '../apollo/queries/GetHomepage';
import { GET_CLIENTS } from '../apollo/queries/GetClients';
import Link from 'next/link';

const Layout = dynamic(() => import('../components/Layout'));
const Seo = dynamic(() => import('../components/Seo'));
const Carousel = dynamic(() => import('../components/Carousel'));

const PRODUCTS = [
  // {
  //   name: 'Complete Curation',
  //   description:
  //     'Helping you curate the best content for your teams, mapped to the skills that matter.',
  //   platformsLink: '/cc/platforms',
  //   enterpriseLink: '/cc/enterprise',
  // },
  {
    name: 'Content to Skills Mapper',
    description:
      'Solving the Content to Skills Mapping challenge automatically.',
    platformsLink: '/content-skills-mapper',
  },
  {
    name: 'Web Curation',
    description:
      'Curate the latest and best of the web to power your learning platform and keep your teams up-to-date on any topic and skills.',
    platformsLink: '/wc/platforms',
    enterpriseLink: '/wc/enterprise',
  },
];

const Home = ({ global }) => {
  const [_document, set_document] = useState(null);
  const [isLessThan870, setIsLessThan870] = useState(false);

  // Load clients
  const clientsQueryRes = useQuery(GET_CLIENTS, {
    context: { clientName: 'strapi' },
    variables: {
      filters: {
        name: {
          eq: 'Testimonials',
        },
      },
    },
    fetchPolicy: 'cache-first',
  });

  // Load homepage
  const homepageQueryRes = useQuery(GET_HOMEPAGE, {
    context: { clientName: 'strapi' },
    fetchPolicy: 'cache-first',
  });

  let CLIENTS = null;

  if (!clientsQueryRes.loading) {
    CLIENTS = clientsQueryRes.data.lists.data.map(
      (list: { attributes: { entities: any } }) => list.attributes.entities
    );
  }

  const textArray = ['Discover', 'Curate', 'Tag'];
  const typingDelay = 200;
  const erasingDelay = 100;
  const newTextDelay = 2000; // Delay between current and next text
  let textArrayIndex = 0;
  let charIndex = 0;

  useEffect(() => {
    set_document(document);
  }, []);

  const typeTimeoutRef = useRef(null);
  const eraseTimeoutRef = useRef(null);

  // Helper function for typing effect
  const type = () => {
    const typedTextSpan = _document.querySelector('.typed-text');
    const cursorSpan = _document.querySelector('.cursor');

    if (charIndex < textArray[textArrayIndex].length && typedTextSpan) {
      if (!cursorSpan?.classList.contains('typing'))
        cursorSpan?.classList.add('typing');

      typedTextSpan.textContent += textArray[textArrayIndex].charAt(charIndex);
      charIndex++;
      typeTimeoutRef.current = setTimeout(type, typingDelay);
    } else {
      cursorSpan?.classList.remove('typing');
      eraseTimeoutRef.current = setTimeout(erase, newTextDelay);
    }
  };

  // Helper function for erasing effect
  const erase = () => {
    const typedTextSpan = _document.querySelector('.typed-text');
    const cursorSpan = _document.querySelector('.cursor');

    if (charIndex > 0 && typedTextSpan) {
      if (!cursorSpan?.classList.contains('typing'))
        cursorSpan?.classList.add('typing');
      typedTextSpan.textContent = textArray[textArrayIndex].substring(
        0,
        charIndex - 1
      );
      charIndex--;
      eraseTimeoutRef.current = setTimeout(erase, erasingDelay);
    } else {
      cursorSpan?.classList.remove('typing');
      textArrayIndex++;
      if (textArrayIndex >= textArray.length) textArrayIndex = 0;
      typeTimeoutRef.current = setTimeout(type, typingDelay + 1100);
    }
  };

  // Effect for handling typing and erasing
  useEffect(() => {
    if (_document && textArray.length) {
      typeTimeoutRef.current = setTimeout(type, newTextDelay + 250);
    }
    return () => {
      clearTimeout(typeTimeoutRef.current);
      clearTimeout(eraseTimeoutRef.current);
    };
  }, [_document, textArray]);

  // Effect for handling window resizing
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setIsLessThan870(newWidth < 870);
    };

    window.addEventListener('resize', updateWindowDimensions);
    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <Layout global={global}>
      {!homepageQueryRes.loading && (
        <Seo seo={homepageQueryRes.data.homepage.data.attributes.seo} />
      )}
      {/* Hero Section */}
      <Box className={styles.section}>
        <Flex
          direction="row"
          className={`${styles.content} ${styles.heroContent}`}
        >
          <Box>
            <Text className={styles.header}>
              AI-powered curated content for learning
              <br />
              <br />
              Powerful search for any topic
              <br />
              Plugged into any platform, mapped to the skills that matter.
            </Text>

            <Box style={{ display: 'flex', marginTop: 60, marginBottom: 60 }}>
              <Text className={`${styles.label} typed-text`}></Text>
              <Text className={`${styles.cursor} cursor`}>&nbsp;</Text>
            </Box>
            <Link
              href={`/contact`}
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              <Button title="Book a consultation" backgroundColor="#CF0032" />
            </Link>
          </Box>
          <Box
            className={styles.background}
            style={{
              backgroundImage: isLessThan870 ? 'none' : `url(background.svg)`,
            }}
          ></Box>
        </Flex>
      </Box>
      {/* Clients And Partners */}
      <Box>
        <Flex className={styles.content}>
          <Box style={{ display: 'flex' }}>
            <Text className={`${styles.line} ${styles.backgroundGreen}`}>
              &nbsp;
            </Text>
            <Text className={styles.title}>
              Partnering with the world&apos;s
              <br />
              leading learning platforms and enterprises
            </Text>
          </Box>
        </Flex>
        {CLIENTS && CLIENTS[0].data.length > 2 && (
          <Carousel paginationAndArrows={false} itemsToShowInitial={4}>
            {CLIENTS[0].data.map((client: any, index: Key) => (
              <>
                <Image
                  key={index}
                  src={client.attributes.logo.data.attributes.url}
                  width={client.attributes.logo.data.attributes.width / 2.5}
                  height={client.attributes.logo.data.attributes.height / 2.5}
                  alt={client.attributes.company}
                  style={{ margin: -20 }}
                />
              </>
            ))}
          </Carousel>
        )}
      </Box>
      {/* Suite Of Products */}
      <Box className={`${styles.section} ${styles.productsContent}`}>
        <Flex className={`${styles.content}`}>
          <Box style={{ display: 'flex' }}>
            <Text className={`${styles.line} ${styles.backgroundWhite}`}>
              &nbsp;
            </Text>
            <Text className={`${styles.title} ${styles.fontWhite}`}>
              Our suite of curation solutions
            </Text>
          </Box>
        </Flex>
        {/* Product Cards */}
        <Carousel
          paginationAndArrows={PRODUCTS.length > 3}
          itemsToShowInitial={3}
        >
          {PRODUCTS.map((product, index) => (
            <ProductCard
              key={index}
              index={index}
              title={product.name}
              description={product.description}
              enterpriseLink={product.enterpriseLink}
              platformsLink={product.platformsLink}
            />
          ))}
        </Carousel>
      </Box>
      {/* Testimonials */}
      {!clientsQueryRes.loading && (
        <Box className={`${styles.section}`}>
          <Flex className={`${styles.content}`}>
            <Box style={{ display: 'flex' }}>
              <Text className={`${styles.line} ${styles.backgroundGreen}`}>
                &nbsp;
              </Text>
              <Text className={`${styles.title}`}>
                What our partners and clients are saying about the Anders Pink
                <br />
                Product Suite?
              </Text>
            </Box>
          </Flex>
          {CLIENTS && (
            <Carousel
              paginationAndArrows={CLIENTS[0].data.length > 2}
              itemsToShowInitial={2}
            >
              {CLIENTS[0].data.map((client: any, index: Key) => (
                <Testimonial key={index} data={client} />
              ))}
            </Carousel>
          )}
          <Box textAlign="center" justifyContent="center" py={20}>
            <Link
              href={`/contact`}
              style={{ textDecoration: 'none' }}
              target="_blank"
            >
              <Button title="Book a consultation" backgroundColor="#CF0032" />
            </Link>
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default Home;
