import styles from '../../styles/Components.module.css';
import { Flex, Box, Text } from '@chakra-ui/react';
import Button from '../CustomButton';
import Link from 'next/link';

const ProductCard = ({
  index,
  title,
  description,
  enterpriseLink,
  platformsLink,
}) => {
  return (
    <Flex direction="column" className={styles.productCard}>
      <Box style={{ height: 100 }}>
        <Text className={styles.header}>{title}</Text>
      </Box>
      <Box style={{ height: 100 }}>
        <Text className={styles.content} fontSize={16}>
          {description}
        </Text>
      </Box>
      <Box style={{ marginTop: 60 }}>
        <Link href={`${platformsLink}`} style={{ textDecoration: 'none' }}>
          <Button title="For Platforms" backgroundColor="#CF0032" margin="0" />
        </Link>
        {index !== 1 && (
          <Link href={`${enterpriseLink}`} style={{ textDecoration: 'none' }}>
            <Button
              title="For Enterprise"
              backgroundColor="#CF0032"
              margin="10px 0px"
            />
          </Link>
        )}
      </Box>
    </Flex>
  );
};

export default ProductCard;
