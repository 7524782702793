import { Button } from '@chakra-ui/react';
import styles from '../../styles/Components.module.css';

const CustomButton = ({
  title,
  onClick = null,
  backgroundColor,
  fontColor = '#ffffff',
  margin = '0',
  size = 'lg',
  maxWidth = '16rem',
  borderColor = 'transparent',
}) => {
  return (
    <Button
      onClick={onClick}
      className={styles.button}
      style={{
        backgroundColor: backgroundColor,
        margin: margin,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: borderColor,
      }}
      size={size}
      rounded={3}
      fontSize={16}
      maxW={maxWidth}
      color={fontColor}
    >
      {title}
    </Button>
  );
};

export default CustomButton;
