import { Text, Box, Image } from '@chakra-ui/react';
import styles from '../../styles/Components.module.css';

const Testimonials = ({ data }) => {
  return (
    <Box justifyContent="center" p="20px">
      <Image
        src={`/quotes-up.png`}
        alt="quotes"
        width={39}
        height={31}
        style={{ marginLeft: 15, zIndex: 1 }}
      />
      <Box className={styles.testimonialCard}>
        {data.attributes.logo.data && (
          <Image
            src={data.attributes.logo.data.attributes.url}
            alt="logo"
            width={200}
            height={150}
            objectFit="contain"
          />
        )}
        <Box
          className={styles.testimonialContent}
          style={{ padding: '0px 20px 0px 20px' }}
        >
          <Text fontSize={24}>{data.attributes.company}</Text>

          <Text color="#5E5E5E" fontSize={14}>
            {data.attributes.testimonial}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonials;
