import { gql } from '@apollo/client';

export const GET_CLIENTS = gql`
  query GetLists($filters: ListFiltersInput) {
    lists(filters: $filters) {
      data {
        attributes {
          name
          entities {
            data {
              attributes {
                company
                position
                url
                testimonial
                shortDescription
                caseStudy {
                  __typename
                  ... on ComponentCasestudyIntro {
                    content
                    title
                  }
                  ... on ComponentCasestudyChallenge {
                    content
                    title
                  }
                  ... on ComponentCasestudyApproach {
                    content
                    title
                  }
                  ... on ComponentCasestudyBenefits {
                    content
                    title
                  }
                }
                logo {
                  data {
                    attributes {
                      name
                      url
                      blurhash
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
